/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import darkReducer from './reducers/darkSlice'
import modalReducer from './reducers/modalSlice'

const rootReducer = combineReducers({
  dark: darkReducer,
  modal: modalReducer,
})

// Setter function for persisting state in local storage
const setLocalStorage = state => {
  try {
    localStorage.setItem('state', JSON.stringify(state))
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

const getLocalStorage = () => {
  if (typeof window !== 'undefined') {
    try {
      const serializedState = localStorage.getItem('state')
      return serializedState ? JSON.parse(serializedState) : undefined
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return undefined
    }
  }
}

const persistedStore = getLocalStorage()
const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedStore,
})

store.subscribe(() => {
  setLocalStorage(store.getState())
})

export default store
