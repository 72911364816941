import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mode: false,
}

const darkSlice = createSlice({
  name: 'dark',
  initialState,
  reducers: {
    setDark: state => {
      state.mode = !state.mode
    },
  },
})

export const { setDark } = darkSlice.actions
export default darkSlice.reducer
