import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isNavOpen: false,
  isSocialOpen: false,
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleNavModal: state => {
      state.isNavOpen = !state.isNavOpen
    },
    toggleSocialModal: state => {
      state.isSocialOpen = !state.isSocialOpen
    },
  },
})

export const { toggleSocialModal, toggleNavModal } = modalSlice.actions
export default modalSlice.reducer
