import '@fontsource/montserrat/latin-300-italic.css'
import '@fontsource/montserrat/latin-400.css'
import '@fontsource/montserrat/latin-500.css'
import '@fontsource/montserrat/latin-500-italic.css'
import '@fontsource/montserrat/latin-700.css'
import '@fontsource/montserrat/latin-900.css'

import '@fontsource/open-sans/latin-300.css'
import '@fontsource/open-sans/latin-500.css'
import '@fontsource/open-sans/latin-600.css'

import '@fontsource/lora/latin.css'
import '@fontsource/dela-gothic-one/latin.css'
import '@fontsource/permanent-marker/latin.css'

// normalize CSS across browsers
import './src/styles/normalize.css'
// custom CSS styles
import './src/styles/style.css'

// Highlighting for code blocks
import 'prismjs/themes/prism.css'

// Redux
import wrapWithProvider from './wrap-with-provider'

export const wrapRootElement = wrapWithProvider
